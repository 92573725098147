import { Spinner } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { Component } from 'react'
import { RouteComponentProps } from 'react-router'
import { Places, TmrZone, Zones } from 'stylewhere/api'
import { Box, Header, Page, Text } from 'stylewhere/components'
import { AppStore, RemoteOperation, Router, TransferToZoneOperationConfig } from 'stylewhere/shared'
import { showToastError } from 'stylewhere/shared/utils'

interface State {
  loading: boolean
  zones: TmrZone[]
}

export default class ExpressTransfer extends Component<RouteComponentProps, State> {
  state: State = {
    loading: true,
    zones: [],
  }

  matchParams = Router.getMatchParams(this.props)
  operation = RemoteOperation.getOperationConfig<TransferToZoneOperationConfig>(this.matchParams.opCode)

  componentDidMount(): void {
    if (!AppStore.defaultWorkstation) {
      Router.navigate('/')
    } else this.getZones()
  }

  getZones = async () => {
    try {
      const zones: TmrZone[] = (
        (await Zones.get('', {
          placeId: AppStore.defaultWorkstation?.placeId ?? '',
        })) as any
      ).content
      const filteredZones = zones.filter((zone) => zone.attributes?.expressTransfer === 'true')
      this.setState({ zones: filteredZones, loading: false })
    } catch (error) {
      showToastError('Error', (error as any).message)
    }
  }

  renderZones = () => {
    const { zones } = this.state
    const preferredZones: string[] = AppStore.defaultWorkstation?.attributes?.preferredZones?.split(',') || []
    //sort zone, first the zones that are in the preferredZones list
    zones.sort((a, b) => {
      if (preferredZones.includes(a.code) && !preferredZones.includes(b.code)) return -1
      if (preferredZones.includes(b.code) && !preferredZones.includes(a.code)) return 1
      return a.code.localeCompare(b.code)
    })
    return (
      <DashView>
        {zones.map((zone) => (
          <ButtonDashboard
            onClick={() =>
              Router.navigate(
                '/transfer-to-zone/:opCode/reading',
                { opCode: this.operation.code },
                {
                  state: {
                    formData: { destinationZoneId: zone, originPlaceId: AppStore.defaultWorkstation?.placeId },
                  },
                }
              )
            }
          >
            <Text
              style={{ fontWeight: preferredZones.includes(zone.code) ? 'bolder' : 'lighter', wordBreak: 'break-word' }}
            >
              {zone.description}
            </Text>
          </ButtonDashboard>
        ))}
        {zones.length % 2 !== 0 && <ButtonDashboard style={{ opacity: 0, pointerEvents: 'none' }} />}
      </DashView>
    )
  }

  render() {
    const { loading } = this.state
    return (
      <Box
        flex
        style={{
          backgroundColor: '#e2e2e2',
        }}
      >
        <Header title={this.operation.description} onBackPress={false} />
        {loading && (
          <Box flex center vcenter>
            <Spinner />
          </Box>
        )}
        {!loading && (
          <Box flex vcenter center>
            {this.renderZones()}
          </Box>
        )}
      </Box>
    )
  }
}

const ButtonDashboard = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  line-height: 29px;
  text-align: center;
  min-width: 290px;
  height: 180px;
  flex: 1;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0 1px 5px 0 #00000014;
  border-radius: 10px;
  margin: 0 30px 30px 0px;
  padding: 20px; /* Added padding to provide space for text */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  word-wrap: break-word; /* Ensure words break within the box */
  white-space: normal; /* Ensure text wraps to the next line */
`

const DashView = styled(Box)`
  margin-right: 10%;
  margin-left: 10%;
  justify-content: center;
  flex: 1;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: center;
  padding-top: 30px;
  flex-basis: 150px;
  flex-shrink: 0;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  & > div {
    flex-basis: calc(33.33% - 20px); /* Ensures three buttons per row with some spacing */
    margin-bottom: 20px; /* Adds space between rows */
  }
`
