import { Places, ShippingParcel, Shippings, TmrPlace } from 'stylewhere/api'
import { RouteComponentProps } from 'react-router'
import { T, __ } from 'stylewhere/i18n'
import { Box, Button, Input, Modal, Spacer } from 'stylewhere/components'
import CoreShippingParcelStart, {
  ShippingParcelStartParams,
  ShippingParcelStartState,
} from 'core/pages/Shipping/ShippingParcelStart'
import { askUserConfirmation, showToast, showToastError } from 'stylewhere/shared/utils'
import { AppStore, Router, Sounds } from 'stylewhere/shared'

interface State extends ShippingParcelStartState {
  isModalOpen?: boolean
  customClearance?: string
}

export default class ShippingParcelStart extends CoreShippingParcelStart<
  RouteComponentProps<ShippingParcelStartParams>,
  State
> {
  getOnConfirmShipmentCB = (askConfirmation: boolean) => async (): Promise<void> => {
    const { asn } = this.state
    if (!asn) {
      showToastError(__(T.misc.shipment), __(T.error.error), this.isModal)
      return
    }

    if (askConfirmation) {
      const ok = await askUserConfirmation(__(T.confirm.confirm_close_asn_title), __(T.confirm.confirm_close_asn_text))

      if (!ok) return
    }

    const currentPlaceId = AppStore?.defaultWorkstation?.placeId
    const workstationPlace: TmrPlace = await Places.get(currentPlaceId ?? '')
    if (!workstationPlace) {
      showToastError(__(T.misc.shipment), __(T.error_messages.missing_workstation_place), this.isModal)
      return
    }

    const placesHasCustomClearanceAttribute = workstationPlace.attributes?.customClearance === 'true'

    if (!placesHasCustomClearanceAttribute) return this.confirm()
    else this.setState({ isModalOpen: true })
  }

  confirm = async () => {
    try {
      const { asn, customClearance } = this.state
      if (!asn) return

      await Shippings.confirmAsn(this.operation, asn.code, {
        transactionDate: new Date().toISOString(),
        customClearance: customClearance,
      })

      showToast({
        title: __(T.misc.success),
        description: __(T.messages.operation_success),
        status: 'success',
      })
      Sounds.success()

      Router.navigate(this.backPath, { opCode: this.operation.code })
    } catch (error) {
      console.error(error)
      showToastError(error, __(T.error.error), this.isModal)
    }
  }

  confirmClearanceCode = () => {
    const { customClearance } = this.state
    if (!customClearance) return
    this.confirm()
  }

  render() {
    const { isModalOpen } = this.state

    return (
      <>
        {super.render()}
        <Modal onClose={() => this.setState({ isModalOpen: false })} visible={isModalOpen}>
          <div>
            <Input label="Declaration Number" onChange={(value) => this.setState({ customClearance: value })} />
            <Box row mt={10}>
              <Button
                variant="default"
                title={__(T.misc.cancel)}
                onClick={() =>
                  this.setState({
                    isModalOpen: false,
                    customClearance: undefined,
                  })
                }
                style={{ flex: 1, minWidth: 'auto', height: 60 }}
              />
              <Button
                title={__(T.misc.confirm)}
                onClick={this.confirmClearanceCode}
                style={{ flex: 1, minWidth: 'auto', marginLeft: 15, height: 60 }}
              />
            </Box>
          </div>
        </Modal>
      </>
    )
  }
}
