import i18n from 'core/shared/i18n'
import en from 'stylewhere/translations/en'
import it from 'stylewhere/translations/it'
import jp from 'stylewhere/translations/jp'
import ch from 'stylewhere/translations/ch'
import { initReactI18next } from 'react-i18next'
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector'
import { StorageKeyName } from '../../core/shared/Storage'
export * from 'core/shared/i18n'

const resources = {
  en: { translation: en },
  it: { translation: it },
  jp: { translation: jp },
  ch: { translation: ch },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(I18nextBrowserLanguageDetector)
  .init({
    resources,
    fallbackLng: 'en',
    supportedLngs: ['en', 'it', 'jp', 'ch'],
    load: 'all',
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['localStorage', 'navigator'],
      lookupLocalStorage: StorageKeyName('language'),
      convertDetectedLanguage: (lng: string) => {
        // remove quotes as our Storage module use JSON.stringify
        if (lng && lng.indexOf('"') !== -1) {
          lng = lng.replace(/"/g, '')
        }
        return lng
      },
    },
  })

//export { default } from 'core/config/i18n'
export default i18n
