export default {
  titles: {
    encoding: 'コード化',
    inbound: 'インバウンド',
    user_info: 'ユーザー情報',
    settings: '設定',
    reset_daily_items: '日常アイテムをリセットする',
    last_parcels: 'ラストパーセルズ',
    select_item: 'アイテムを選択',
    select_workstation: 'ワークステーションの選択',
    select_language: '言語の選択',
    support: 'サポート',
    select_printer: 'プリンターの選択',
    shipment_to_confirm: 'シップメント確認',
    sorting_details: 'ベンチレーション詳細',
    inventories: 'インベントリ',
    pin_modal: 'ピンを入力して関連付けを強制します',
    shipments: 'シップメント',
  },
  fields: {
    current_location: '現在位置',
    identified_item: '識別されたアイテム',
    ignored_items: '無視されるアイテム',
    ignored_with_reason_items: '理由付きで無視されるアイテム',
    error_items: 'エラーアイテム',
    warning_items: '警告アイテム',
    umg: 'Umg',
    custom_class: 'カスタムクラス',
    product_code: '製品コード',
    as400username: 'ユーザー名 AS400',
  },
  misc: {
    or: 'または',
    select_place: 'こんにちは {{user}}、続行するには起源地を選択してください',
    back: '戻る',
    refresh: 'リフレッシュ',
    save: '保存',
    confirm: '確定',
    no_carton_found: 'カートンが見つかりません',
    confirm_continue: '確定して続行',
    cancel: 'キャンセル',
    close: 'クローズ',
    user: 'ユーザー',
    workstation: 'ワークステーション',
    username: 'ユーザー名',
    name: '名前',
    password: 'パスワード',
    login: 'ログイン',
    encoding: 'コード化',
    code: 'コード',
    ean_code: 'EANコード',
    certilogo: 'サーティロゴ',
    enter_the_necessary_fields: '必要なフィールドに入力して操作を開始します',
    associated_items: '関連アイテム',
    associate: '関連付ける',
    associated: '関連付けられた',
    verify: '確認する',
    reading: '読み込む',
    processing: '処理',
    association: '関連',
    associations: '関連',
    waiting: '待機',
    writing: '書き込み',
    partial_confirm: '部分確定',
    retry: '再試行',
    writing_association: '書込みと関連付け',
    confirmed: '確定済み',
    unable_to_write: '製品: {{productCode}} の epc タグ: {{code}} を書き込めません',
    unable_to_get_next_epc: '次の EPC を取得できません: {{productCode}}',
    unable_to_connect_to_workstation: 'ワークステーションに接続できません',
    tag_info: 'タグ情報',
    inbound: 'インバウンド',
    outbound: 'アウトバウンド',
    stop: '停止',
    start: '開始',
    pause: '一時停止',
    clear: 'クリア',
    tag_encoded_today: '今日のタグエンコード',
    transaction_date: '取引日',
    select_transaction_date: '取引日を選択してください',
    ready_to_receive: '受け取り準備完了',
    change_password: 'パスワードを変更する',
    logout: 'ログアウト',
    are_you_sure_to_logout: '本当にログアウトしますか？',
    settings: '設定',
    roles: '役割',
    email: 'Eメール',
    place: '場所',
    items: '複数アイテム',
    description: '説明',
    color: 'カラー',
    size: 'サイズ',
    part: '一部',
    total_reads: '合計読み取り数',
    total_ok: '全く問題ありません',
    save_to_list: 'リストに保存',
    destination: '送り先',
    origin: '起源',
    new_parcel: '新しいパーセル',
    status: '状態',
    parcel: 'パーセル',
    read_parcel: 'パーセルを読み込む',
    parcels: 'パーセル',
    shipment: 'シップメント',
    creation_date: '作成日',
    search: '検索',
    select_search: '検索...',
    detail: '詳細',
    quantity: '数量 ({{quantity}})',
    only_quantity: '数量',
    style: 'スタイル',
    material: 'マテリアル',
    shipment_code_auto: 'シップメントコード (自動)',
    parcel_code_auto: 'パーセルコード（自動）',
    next: '次',
    parcels_to_receive: '受領するパーセル',
    confirm_parcel: 'パーセルの確定',
    image_missing: '画像がありません',
    image: '画像',
    loading: '読み込み中...',
    unknown_product: '不明な製品',
    unknown_tag: '不明なタグ',
    invalid_tag: '無効なタグ',
    stock: 'ストック',
    valid: '有効',
    unknown: '未知',
    actions: 'アクション',
    reprint: '再印刷',
    product: '製品',
    product_items: '製品アイテム',
    identifiers: '識別子',
    zone: 'ゾーン',
    report_repair: '修理の報告',
    reason: '理由',
    request_code_change: 'コード変更のリクエスト',
    enable_workstation_emulation: 'ワークステーションエミュレーションを有効にする',
    filter: 'フィルター',
    language: '言語',
    warning: '警告',
    success: '成功',
    info: '情報',
    checklist: 'チェックリスト',
    optional: 'オプション',
    phone: '電話',
    version: 'バージョン',
    printer: 'プリンター',
    new_shipment: '新しいシップメント',
    upc: 'UPC',
    model_part_color_size: 'モデル/パーツ/カラー/サイズ',
    confirm_shipment: 'シップメントの確定',
    receive: '受領',
    delete: '削除',
    delete_association: '関連付けの削除',
    change: '変更',
    from: 'から',
    to: 'まで',
    ignored: '無視',
    ignored_with_reason: '理由をつけて無視される',
    yes: 'はい',
    no: 'いいえ',
    model: 'モデル',
    typology: 'タイポロジィ',
    no_result: '検索結果はありません',
    expected: '予測される',
    read: '読む',
    missing: '不足',
    unexpected: '予想外',
    unexpected_parcel: '予期しないパーセル',
    ignored_plural: '無視',
    serial: 'シリアル',
    priority: '優先度',
    group: 'グループ',
    required_quantity: '必要数量',
    sorted_quantity: 'ベンチレーション数量',
    missing_quantity: '不足数量',
    barcode_bc: 'バーコードBC',
    sorted_date: 'ベンチレーション日',
    customer: '顧客名',
    list: 'リスト',
    list_position: 'リストの位置',
    select_date: '日付を選択してください',
    item_info: 'アイテム情報',
    item_events: 'アイテムイベント',
    operation_type: '操作タイプ',
    operation_place: '操作場所',
    loading_data: 'データをロード中です。お待​​ちください。',
    empty_item_events: 'イベントが見つかりませんでした',
    show_more: 'もっと表示する',
    hide_more: 'もっと非表示する',
    production_order: '製造オーダー',
    production_order_row: '製造オーダー行',
    production_order_sku: '製造オーダーのSKU',
    kill_tag: 'タグの削除',
    item_order: 'アイテムの注文',
    item_row: 'アイテム行',
    pack_code: 'パッケージコード',
    send_reads: '読み取りの送信',
    items_add_inventory_success: '在庫にアイテムが追加されました',
    new: '新しい',
    confirm_mission: 'ミッションを確定する',
    confirm_mission_text: 'ミッションを確定してもよろしいですか？',
    parziali: '部分的',
    total_quantity: '総量',
    accepted_quantity: '受領数量',
    country_check: '国の管理',
    showcase_check: 'ショーケース管理',
    attention: '注意',
    clear_items: '続行するとすべての測定値が削除されます。続行しますか?',
    add_partial: '注意',
    add_partial_text: '品質保証ミッションの作成が必要になる場合があります',
    add_partial_already_text:
      '品質保証ミッションの作成が必要になる場合があります。 UMGはすでに機能しています。読み込んだアイテムを上書きしますか?',
    error_cq_mission: '注意、品質管理のミッションを作成する',
    warning_zone_items: 'IN_ANOTHER_ZONE',
    internal_tag: '内部タグ',
    external_tag: '外部タグ',
    error_tag: 'エラータグ',
    awaiting: '待機',
    error_logs: 'エラーログ',
    event_logs: 'イベントログ',
    box_closed: 'クローズされたボックス',
    unexpected_list: '予想外のアイテムリスト',
    pending_tags: '保留中のタグ',
    selected_zone: '選択されたゾーン',
    pricing: '価格設定',
    send_to_sap: 'SAPに送信',
    write_tag: 'タグの書き込み',
    identifier_tag: '識別タグ',
    digit_identifier_tag: '書き込む数字タグ',
    target_epc: 'ターゲットEPC',
    retry_write_tag: '書き込み処理中にエラーが発生しました。もう一度試しますか?',
    ready: '準備',
    offline: 'オフライン',
  },
  error: {
    error: 'エラー',
    errors: 'エラー',
    forbidden: '禁止',
    not_found: '見つかりません',
    unauthorized: '無許可',
    too_many_tags_read: '読み取られたタグが多すぎます (期待値 {{expected}} が {{read}} を読み取りました)',
    too_many_checklist_types:
      'チェックリストの種類が多すぎます。必ず同じチェックリストタイプのパーセルを選択してください。',
    rfid_discrepancies_found: 'RFID の不一致が見つかりました。すべてクリアして再試行するか、不一致を確認してください。',
    certilogo_not_found: 'サーティロゴ"{{code}}"が見つかりません"',
    ean_not_found: '"EAN"{{code}}"が見つかりません"',
    production_order_not_found: '製造オーダー"{{code}}"が見つかりません"',
    no_inbound_zones: 'インバウンドゾーンがありません',
    no_operation_config: '動作設定が見つかりません',
    not_supported_reading_mode: '読み取りモードはサポートされていません',
    undefined_operation_config: '操作構成を定義解除することはできません',
    certilogo_already_encoded: 'サーティロゴ"{{code}}"はすでにエンコードされています"',
    tag_not_found: 'タグが見つかりません',
    missing_printer_id: 'プリンターIPがありません',
    tag_unassociated: 'タグが関連付けられていません',
    tag_type_unexpected: '予期しないタグの種類',
    tag_already_associated: 'タグはすでに関連付けられています',
    tags_not_associated: '1 つ以上のタグが関連付けられていません',
    tags_mismatch: 'タグが一致しません',
    workstation_could_not_be_started: 'ワークステーション {{code}} を起動できませんでした',
    authentication_failed: '認証に失敗しました',
    field_required: 'フィールド {{label}} は必須です',
    unable_to_connect_to_rfid_reader:
      'RFIDリーダーに接続できません。デバイスの電源がオンになっていることを確認してください。',
    items_in_error_found: 'エラーのあるアイテムが見つかりました',
    item_not_in_inbound: 'アイテムが受領されていません',
    item_not_in_stock: '在庫がない商品',
    item_in_another_parcel: '別のパーセル内のアイテム',
    item_in_another_production_order: '別の製造オーダーのアイテム',
    connection: '接続エラー',
    connection_details:
      'サーバーから応答がありませんでした。インターネット接続または VPN 構成が機能していることを確認してください。',
    field_invalid_default: 'フィールド {{field}} のデフォルト値"{{defaultValue}}"が無効です"',
    field_invalid_default_from_endpoint: 'フィールド {{field}} の値が見つかりませんでした',
    sorting_already_read: 'シリアル番号 {{serial}} のアイテムはグループ {{group}} ですでに読み取られています',
    sorting_no_group: 'アイテムはソートできません',
    sorting_not_in_zone: 'シリアル番号 {{serial}} のアイテムは並べ替えできません。すでにゾーン {{zone}} 内にいます',
    sorting_unknown: 'タグ {{tag}} は無効です',
    sorting_unexpected: '予期しないタグ',
    no_item_configuration: 'アイテム構成が見つかりません',
    item_creation_error: 'アイテム作成エラー',
    couldn_find_production_order: '製造オーダーが見つかりませんでした',
    couldn_find_production_order_row: '製造オーダーの行が見つかりませんでした',
    missing_operation_option: '操作オプションがありません: {{error}}',
    item_session_list_create: '未作成のリスト',
    item_session_list_create_session: '未作成のセッションリスト',
    item_session_list_confirm: '未確認リスト',
    item_session_list_confirm_session: '未確認セッションリスト',
    form_validation: '検証フォーム',
    form_validation_message: 'フィールドへの入力エラー',
    select_place_error: '起源地を保存できませんでした',
    self_user_data: 'データを保存できませんでした',
    encoding_no_tags: 'これ以上タグは読み取られませんでした。操作は中止されました。',
    only_virgin_tag: 'バージンタグのみが検出されました',
    tags_mismatch_error: '検出された製品が多すぎます',
    tags_errors: 'タグエラー',
    value_not_valid: '入力された値は無効です',
    write_tag: 'タグの書き込み中に不明なエラーが発生しました',
    WRONG_PRODUCTION_ORDER: '不正な製造順序',
    WRONG_PLACE: '不正な場所',
    PRODUCTION_ORDER_ROW_CREATION_ERROR: '製造オーダー行作成エラー',
    force_tag_associated: 'タグはすでに関連付けられています。再度関連付けますか?',
    operation_confirm_error: '動作確認エラー',
    missing_mandatory_qc: '品質管理はありません。続行しますか？',
    wrong_qc_status: 'この製品はKOと宣言されました。続行しますか？',
    wrong_qc_size: 'サイズを確認してください。続行しますか？',
    wrong_qc_size_payload:
      'サイズを確認してください。エンコード サイズ: {{productSize}} - 物理サイズ: {{manualSize}}。続行しますか？',
    wrong_qc_model: 'モデルを確認してください。続行しますか？',
    start_checklist: '未開始チェックリスト',
    confirm_with_unexpected: '予期しないタグで確定できません',
    confirm_with_overqty: '数量を超えたタグでは確定できません',
    rfid_reader_initialization: 'RFIDリーダーの初期化中に不明なエラーが発生しました',
    remove_sap_item_error: 'アイテムを削除できません',
    no_asn_selected: 'ASNが選択されていません',
    submit_asn: 'ASN送信エラー',
  },
  messages: {
    no_operations_found: '操作が見つかりませんでした。コントロールパネルでユーザー権限を確認してください',
    no_workstations_found: 'ワークステーションが見つかりません',
    no_workstation_selected: 'ワークステーションが選択されていません',
    are_you_sure_to_reset_daily_items: '日次アイテムをリセットしてもよろしいですか?',
    bring_an_item_to_antenna: 'アンテナにアイテムを近づけてください',
    press_start_to_read: '[スタート]ボタンを押して、RFID 製品の読み取りを開始します',
    reading_in_progress: '読み取り中',
    waiting_for_product: '商品を待っています...',
    paste_comma_separeted_tags: 'カンマ区切りタグを貼り付けて、Return キーを押します...',
    connection_workstation_progress: 'ワークステーションへの接続が進行中です…',
    operation_success: '操作は正常に完了しました',
    outbound_success: 'アウトバウンドが正常に確定されました',
    inbound_success: 'インバウンドが正常に確定されました',
    pack_success: 'パッキングが正常に確定されました',
    transfer_to_zone_success: 'ゾーンへの転送が正常に確定されました',
    simple_item_list_success: 'シンプルアイテムリストが正常に確定されました',
    list_empty: 'リストが空です',
    looks_like_anything_in_list: 'リストには何もないようです',
    generic_success: '{{code}} が正常に確定されました',
    generic_success_canceled: '{{code}} は正常にキャンセルされました',
    generic_error: '{{code}} エラー',
    fill_all_fields_and_search_an_item_configuration: 'すべてのフィールドに入力し、アイテム構成を検索します',
    encoding_success: 'エンコードが正常に完了されました',
    read_some_identifier_to_start_verification: '識別子を読み取って検証を開始します',
    warning_items_found: '警告アイテムが見つかりました',
    are_you_sure_you_want_to_confirm: '本当に確定してもよろしいですか？',
    item_session_list_confirm: 'リストは正常に確定されました',
    operation_in_progress: '操作が進行中です。お待​​ちください...',
    sorting_error_zones: '起源ゾーンが評価されず、循環を継続するのは不可能です',
    connection_in_progress: '接続中...',
    checklist_there_are: 'がある',
    checklist_missings: '不足アイテム',
    checklist_overqty: '超過アイテム',
    checklist_unexpecteds: '予期しないアイテム',
    checklist_confirm_mismatch_question: '本当に確定してもよろしいですか?',
    force_update_content: 'SAP に強制的に送信してもよろしいですか?',
    connection_to_station_in_progress: 'ステーションに接続中です',
    tag_killed: 'タグは破壊されました',
    tag_deleted: 'タグが削除されました',
    write_tag: 'タグの書き込みに成功しました',
  },
  placeholder: {
    search_shipment_parcel: 'シップメントまたはパーセルの検索',
    search_product_parcel: '商品またはパーセルの検索',
  },
  confirm: {
    discrepancy_title: '不一致のあるパーセル',
    discrepancy_message: '相違のある商品が見つかりました。パーセルを確定してもよろしいですか?',
    confirm_delete_association: 'このアイテムの関連付けを削除してもよろしいですか?',
    confirm_delete_operation_title: '{{operation}}を削除',
    confirm_delete_operation: '操作を削除しますか?読み取ったアイテムはすべて失われます。',
    confirm_delete_item_association: 'このアイテムの関連付けを削除してもよろしいですか?',
    confirm_kill_tag: 'このタグを破壊してもよろしいですか?',
    confirm_operation_title: '{{operation}}を確認してください',
    confirm_operation_text: '動作を確定してもよろしいですか？',
    confirm_operation_text_irreversible: '動作を確定してもよろしいですか？この操作は元に戻すことはできません。',
    confirm_operation_text_partial_confirm: '読み取り値を JDE に送信します。続行してもよろしいですか?',
    post_confirm_action_title: '継続',
    post_confirm_action_keep_input: '操作が完了しました。別の操作を実行しますか?',
    post_confirm_action_change_input: '操作が完了しました。別の操作を実行しますか?',
  },
  labels: {
    draft: 'ドラフト',
    ready_to_send: '送信する準備ができました',
    send_await: '保留中',
    outbound_error: 'アウトバウンドエラー',
    sent: '送信済み',
    in_inbound: 'インバウンドで',
    receive_await: '保留中',
    ready_to_receive: '受領',
    inbound_error: 'インバウンドエラー',
    received: '受領済',
    in_transit: '輸送中',
  },
  report_tbox: {
    umg: 'Umg',
    ddt_number: 'DDT N.',
    creation_date: '日付',
    capi_mdc: 'キャピ MDC',
    qt_totale: '合計数量',
    qt_accettata: '受領された数量',
    qt_scartata: '拒否された数量',
    supplier: 'サプライヤー',
    notes: 'ノート',
  },
  error_messages: {
    missing_workstation_place: 'ワークステーションには場所がありません。場所を選択してください',
  },
  custom: {
    countTBoxParcel: 'パーセル',
    countTBoxDDT: 'DDT',
    BARCODE_READ: 'バーコード読み取り',
    RFID_START_READING: '読み取りを開始',
    RFID_STOP_READING: '読み取りを中止',
    RESULT_EVENT: 'イベント結果',
    TRACE: 'トレース',
    INFO: '情報',
    WARNING: '警告',
    ERROR: 'エラー',
    deleteSession: 'パーセルの削除',
    deleteSessionMessage: 'パーセルを削除してもよろしいですか?',
    deleteList: 'DDTの削除',
    deleteListMessage: 'ddt を削除してもよろしいですか?',
    WAM: 'SKU',
    sap_warning: 'SAPの警告',
    total_list: '総リスト',
    total_parcel: 'パーセル合計',
    order_number: '注文番号',
    addon: 'アドオン',
  },
  imbustatrice: {
    and: 'そして',
    unkwon_tag: '不明なタグ',
    dynamic_tag: '{{role}} タグ',
    dynamic_tag_missing: '{{role}} タグがありません',
    too_many_dynamic_tags: '{{role}} タグが多すぎます',
    dynamic_tag_already_associated: '{{role}} タグはすでに関連付けられています',
    wrong_tag: '不正なタグ',
  },
  identifier_error: {
    ALREADY_ASSOCIATED_TAG: 'すでに関連付けられています',
    WRONG_QC_STATUS: '不正な品質管理ステータス',
    WRONG_QC_SIZE: '不正な品質管理サイズ',
    WRONG_QC_MODEL: '不正な品質管理モデル',
    MISSING_MANDATORY_QC: '品質管理が不足しています',
    MISSING_PRODUCT_INFO: '製品情報がありません',
    WRONG_TAG: '不正なタグ',
    UNKNOWN_TAG: '不明なタグ',
    WRONG_PRODUCT: '不正な製品',
    MISSING_MANDATORY_TAG: '必須タグがありません',
    MISSING_OPTIONAL_TAG: 'オプションのタグがありません',
    EXTRA_TAG: '追加のタグ',
    TAG_MISMATCH: 'タグの不一致',
    PRODUCTIONORDERROW_MISMATCH: '製造オーダー行の関連付けが一致していません',
    MISSING_SYSTEM_TAG: '不明なタグ',
  },
  statuses: {
    RESTORE_VARIANT: 'タイポロジーの復元',
  },
}
