import { Routes as CoreRoutes } from 'core/pages'
import ShippingParcelStart from './ShippingParcelStart'
import ExpressTransfer from './ExpressTransfer'

///////////////////////////////////////////////////////////////////////////////
// Custom & core overrides, change only this!
///////////////////////////////////////////////////////////////////////////////

/*
const CustomRoutes = {
  '/simple-item-list/:opCode/reading': { component: SimpleItemListReading },
  '/simple-parcel-list/:opCode': { component: SimpleParcelListStart },
}
*/

const CustomDirectRoutes = {}
const CustomRoutes = {
  '/shipping/:opCode/asn/:asnId': { component: ShippingParcelStart },
  '/transfer-to-zone/express-transfer/:opCode': { component: ExpressTransfer },
}

///////////////////////////////////////////////////////////////////////////////

export const Routes = { ...CustomDirectRoutes, ...CoreRoutes, ...CustomRoutes }
// eslint-disable-next-line
export type Routes = keyof typeof Routes
